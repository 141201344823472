

























import {
    Component,
    Prop,
    Vue,
    Emit,
    PropSync,
    Ref
} from 'vue-property-decorator';

@Component
export default class InputFile extends Vue {
    @Ref() file!: HTMLInputElement;
    @Prop({
        type: String,
        default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
    })
    mimes!: string;
    @Prop({ type: String }) readonly placeholder!: string;
    @Prop({ type: Boolean, default: false }) readonly invalid!: boolean;
    @PropSync('filename', { type: String }) name!: string;

    remove() {
        this.name = '';
        this.$emit('input', null);
        this.file.value = '';
    }

    @Emit()
    input(files: FileList) {
        this.name = files[0] ? files[0].name : this.name;
        return files[0];
    }
}

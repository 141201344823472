
































import { Component, Vue, PropSync, Emit, Prop } from 'vue-property-decorator';
import { trainingModule } from '@/store/modules/training';
import moment from 'moment';
import { User } from '@/interfaces/user';
import InputFile from '@/components/InputFile.vue';
import { agentModule } from '@/store/modules/agent';

interface FormSecondStepInterface extends Pick<User, 'email'> {
    code?: string;
    token?: string;
    training?: string;
    document?: File;
    filename?: string;
}

@Component({
    components: { InputFile }
})
export default class FormSecondStep extends Vue {
    @PropSync('step', { type: Number, default: 3 }) currentStep!: number;
    @PropSync('form') data!: FormSecondStepInterface;
    @Prop({ type: Boolean, default: false }) hiddenCode!: boolean;

    get options() {
        return trainingModule.trainings.map((training) => ({
            ...training,
            value: training._id,
            label: `${moment(training.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                .locale('es')
                .format('dddd, DD MMM YYYY[.] [De] HH:mm [hrs] -')} ${moment(
                training.endDate,
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ).format('HH:mm [hrs]')}`,
            rest:
                training.capacity - training.users.length === 1
                    ? `(Queda ${
                          training.capacity - training.users.length
                      } cupo)`
                    : training.capacity - training.users.length < 1
                    ? '(Agotado)'
                    : `(Quedan ${
                          training.capacity - training.users.length
                      } cupos)`
        }));
    }

    mounted() {
        trainingModule.index();
    }
}

import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Training } from '@/interfaces/training';

@Module({ generateMutationSetters: true })
export class TrainingModule extends VuexModule {
    trainings: Training[] = [];

    @Action
    async index() {
        const { data } = await Axios.get<Training[]>('training/availables');
        this.trainings = data;
    }
}
export const trainingModule = new TrainingModule({
    store,
    name: 'training'
});
